/**
 * Define the Route List items
 */
import React from "react";
import NoPermission from "../Modules/NoPermission/NoPermission";
import { faCircle } from "@fortawesome/free-regular-svg-icons";

import Splash from "../Modules/Login/Pages/Splash";
import AddUser from "../Modules/User/Pages/AddUser";
import AzureRedirectComponent from "../Modules/Login/Pages/AzureRedirectComponent";
import SocialMediaRedirectComponent from "../Modules/Login/Pages/SocialMediaRedirectComponent";
import Register from "../Modules/Login/Pages/Register";
import NewPassword from "../Modules/Login/Pages/NewPassword";
import {
  faBug,
  faBox,
  faBoxOpen,
  faGlobe,
  faMicrochip,
  faTimeline,
  faTerminal,
  faGear,
  faFileContract,
  faUser,
  faFolderTree,
  faDatabase,
  faLaptopFile,
  faFileCircleCheck,
} from "@fortawesome/free-solid-svg-icons";
import Products from "../Modules/Products/Pages/Products";
import LoadCommands from "../Modules/LoadCommands/Pages/LoadCommands";
import Tests from "../Modules/Test/Pages/Tests";
import AddTest from "../Modules/Test/Pages/AddTest";
import AddLCS from "../Modules/LoadCommands/Pages/AddLCS";
import UserTenant from "../Modules/Login/Pages/UserTenant";
import TestCases from "../Modules/TestCases/Pages/TestCases";
import AddTestCase from "../Modules/TestCases/Pages/AddTestCase";
import DeviceEnrollment from "../Modules/DeviceEnrollment/Pages/DeviceEnrollment";
import RemoteServers from "../Modules/RemoteServer/Pages/RemoteServers";
import AddRemoteServer from "../Modules/RemoteServer/Pages/AddRemoteServer";
import RunSets from "../Modules/RunSet/Pages/RunSets";
import AddRunSet from "../Modules/RunSet/Pages/AddRunSet";
import RunSetDetail from "../Modules/RunSet/Pages/RunSetDetail";
import Profile from "../Modules/Profile/Profile";
import Executions from "../Modules/Execution/Pages/Executions";
import ExecutionDetail from "../Modules/ExcecutionDetail/Pages/ExecutionDetail";
import AddRFProfile from "../Modules/RFProfile/Pages/AddRFProfile";
import RFProfileGroup from "../Modules/RFProfileGroup/Pages/RFProfileGroup";
import AddRFProfileGroup from "../Modules/RFProfileGroup/Pages/AddRFProfileGroup";
import Organization from "../Modules/Organization/Organization";
import RFFormInfo from "../Modules/RFFormInfo/Pages/RFFormInfo";
import AddRFFormInfo from "../Modules/RFFormInfo/Pages/AddRFFormInfo";
import RFFieldInfo from "../Modules/RFFieldInfo/Pages/RFFieldInfo";
import AddRFFieldInfo from "../Modules/RFFieldInfo/Pages/AddRFFieldInfo";
import OrganizationProducts from "../Modules/OrganizationProducts/Pages/OrganizationProducts";
import OrganizationUser from "../Modules/OrganizationUsers/Pages/OrganizationUser";

const RoutesItemsList = [
  {
    text: "",
    icon: "",
    route: "/login",
    element: <Splash />,
    layouts: "EmptyLayout",
    is_drawer_item: "false",
    page_title: "Login",
    isauth: false,
    is_mobile: "true",
  },
  {
    text: "",
    icon: "",
    route: "/user/tenant",
    element: <UserTenant />,
    layouts: "EmptyLayout",
    is_drawer_item: "false",
    page_title: "Login",
    isauth: true,
    is_mobile: "true",
  },
  {
    text: "",
    icon: "",
    route: "/register",
    element: <Register />,
    layouts: "EmptyLayout",
    is_drawer_item: "false",
    page_title: "Register",
    isauth: false,
    is_mobile: "true",
  },
  {
    text: "",
    icon: "",
    route: "/new-password/:token?",
    element: <NewPassword />,
    layouts: "EmptyLayout",
    is_drawer_item: "false",
    page_title: "New Password",
    isauth: false,
    is_mobile: "true",
  },
  {
    id: 3,
    text: "Products",
    icon: "",
    route: "/",
    element: <Products />,
    layouts: "CardLayout",
    is_drawer_item: "true",
    page_title: "Products",
    isauth: true,
    is_mobile: "true",
    iconsvg: faBox,
  },
  {
    id: 25,
    text: "Profile",
    icon: "",
    route: "/profile",
    element: <Profile />,
    layouts: "TileLayout",
    is_drawer_item: "false",
    page_title: "Profile",
    isauth: true,
    is_mobile: "true",
    iconsvg: faUser,
  },
  {
    text: "No Permission",
    route: "/no-permission",
    element: <NoPermission />,
    layouts: "TileLayout",
    is_drawer_item: "false",
    is_mobile: "false",
  },
  {
    text: "AuTest",
    icon: "k-i-tell-a-friend",
    ["data-expanded"]: false,
    id: 4,
    page_title: "Automation Test",
    layouts: "CardLayout",
    is_drawer_item: "true",
    isauth: false,
    is_mobile: "true",
    iconsvg: faBug,
    AuthPermission: "Autest",
  },
  {
    text: "Global",
    icon: "k-i-minus",
    id: 5,
    parentid: 4,
    ["data-expanded"]: false,
    layouts: "CardLayout",
    page_title: "Global",
    is_drawer_item: "true",
    isauth: false,
    is_mobile: "true",
    iconsvg: faGlobe,
  },
  {
    text: "Command Maintenance",
    icon: "k-i-minus",
    id: 10,
    parentid: 5,
    route: "/load-command-screen",
    layouts: "CardLayout",
    element: <LoadCommands />,
    page_title: "Command Maintenance",
    is_drawer_item: "true",
    isauth: true,
    is_mobile: "true",
    iconsvg: faMicrochip,
  },
  {
    text: "Test",
    icon: "k-i-minus",
    id: 11,
    parentid: 5,
    route: "/tests",
    layouts: "CardLayout",
    element: <Tests />,
    page_title: "Test",
    is_drawer_item: "true",
    isauth: true,
    is_mobile: "true",
    iconsvg: faFileCircleCheck,
  },
  {
    text: "AddTest",
    icon: "k-i-minus",
    id: 12,
    parentid: 5,
    route: "/test",
    layouts: "TileLayout",
    element: <AddTest />,
    page_title: "Add Test",
    is_drawer_item: "false",
    isauth: true,
    is_mobile: "true",
    iconsvg: faCircle,
  },
  {
    text: "AddLCS",
    icon: "k-i-minus",
    id: 13,
    parentid: 5,
    route: "/lcs",
    layouts: "TileLayout",
    element: <AddLCS />,
    page_title: "Add Command",
    is_drawer_item: "false",
    isauth: true,
    is_mobile: "true",
    iconsvg: faCircle,
  },
  {
    text: "Test Case",
    icon: "k-i-minus",
    id: 16,
    parentid: 5,
    route: "/testcases",
    layouts: "CardLayout",
    element: <TestCases />,
    page_title: "Test Case",
    is_drawer_item: "true",
    isauth: true,
    is_mobile: "true",
    iconsvg: faLaptopFile,
  },
  {
    text: "Add Test Case",
    icon: "k-i-minus",
    id: 12,
    parentid: 5,
    route: "/testcase",
    layouts: "TileLayout",
    element: <AddTestCase />,
    page_title: "Add Test Case",
    is_drawer_item: "false",
    isauth: true,
    is_mobile: "true",
    iconsvg: faFileContract,
  },
  {
    text: "Add Test Case",
    icon: "k-i-minus",
    id: 12,
    parentid: 5,
    route: "/testcase",
    layouts: "TileLayout",
    element: <AddTestCase />,
    page_title: "Add Test Case",
    is_drawer_item: "false",
    isauth: true,
    is_mobile: "true",
    iconsvg: faCircle,
  },
  {
    text: "Runtime",
    icon: "k-i-minus",
    id: 6,
    parentid: 4,
    ["data-expanded"]: false,
    layouts: "CardLayout",
    page_title: "Runtime",
    is_drawer_item: "true",
    isauth: false,
    is_mobile: "true",
    iconsvg: faTerminal,
  },
  {
    text: "Remote Server",
    icon: "k-i-minus",
    id: 21,
    parentid: 6,
    route: "/remote-servers",
    layouts: "CardLayout",
    element: <RemoteServers />,
    page_title: "Remote Server",
    is_drawer_item: "false",
    isauth: true,
    is_mobile: "false",
    iconsvg: faDatabase,
  },
  {
    text: "Add Remote Server",
    icon: "k-i-minus",
    id: 17,
    parentid: 6,
    route: "/remote-server",
    layouts: "TileLayout",
    element: <AddRemoteServer />,
    page_title: "Add Remote Server",
    is_drawer_item: "false",
    isauth: true,
    is_mobile: "true",
    iconsvg: faCircle,
  },
  {
    text: "Run Set",
    icon: "k-i-minus",
    id: 18,
    parentid: 6,
    route: "/run-sets",
    layouts: "CardLayout",
    element: <RunSets />,
    page_title: "Run Set",
    is_drawer_item: "true",
    isauth: true,
    is_mobile: "true",
    iconsvg: faFolderTree,
  },
  {
    text: "Add Run Set",
    icon: "k-i-minus",
    id: 19,
    parentid: 6,
    route: "/run-set",
    layouts: "TileLayout",
    element: <AddRunSet />,
    page_title: "Add Run Set",
    is_drawer_item: "false",
    isauth: true,
    is_mobile: "true",
    iconsvg: faCircle,
  },
  {
    text: "Run Set Detail",
    icon: "k-i-minus",
    id: 20,
    parentid: 6,
    route: "/run-set-details",
    layouts: "TileLayout",
    element: <RunSetDetail />,
    page_title: "Run Set Detail",
    is_drawer_item: "false",
    isauth: true,
    is_mobile: "true",
    iconsvg: faCircle,
  },
  {
    text: "Executions",
    icon: "k-i-minus",
    id: 26,
    parentid: 4,
    route: "/executions",
    layouts: "CardLayout",
    page_title: "Executions",
    element: <Executions />,
    is_drawer_item: "true",
    isauth: true,
    is_mobile: "true",
    iconsvg: faGlobe,
  },
  {
    text: "Execution Detail",
    icon: "k-i-minus",
    id: 27,
    parentid: 4,
    route: "/execution",
    layouts: "TileLayout",
    page_title: "Execution Detail",
    element: <ExecutionDetail />,
    is_drawer_item: "false",
    isauth: true,
    is_mobile: "true",
    iconsvg: faGlobe,
  },
  {
    text: "Execution",
    icon: "k-i-minus",
    id: 7,
    parentid: 4,
    route: "/execution-maintenance",
    layouts: "CardLayout",
    element: <AddUser />,
    page_title: "Execution",
    is_drawer_item: "false",
    isauth: true,
    is_mobile: "true",
    iconsvg: faTimeline,
  },
  {
    text: "RF++",
    icon: "k-i-tell-a-friend",
    ["data-expanded"]: false,
    id: 24,
    page_title: "RF++",
    layouts: "CardLayout",
    is_drawer_item: "true",
    isauth: false,
    is_mobile: "true",
    iconsvg: faBoxOpen,
    AuthPermission: "RF",
  },
  {
    text: "Device Enrollment",
    parentid: 24,
    id: 23,
    icon: "k-icon-action",
    route: "/device-enrollment",
    layouts: "CardLayout",
    element: <DeviceEnrollment />,
    page_title: "Device Enrollment",
    is_drawer_item: "true",
    isauth: true,
    is_mobile: "true",
    iconsvg: faGear,
  },
  {
    text: "Profiles",
    icon: "k-i-minus",
    id: 28,
    parentid: 24,
    route: "/rf-profile-groups",
    layouts: "CardLayout",
    element: <RFProfileGroup />,
    page_title: "Profile Group",
    is_drawer_item: "true",
    isauth: false,
    is_mobile: "true",
    iconsvg: faFileCircleCheck,
  },
  {
    text: "Add Profile Group",
    icon: "k-i-minus",
    id: 31,
    parentid: 24,
    route: "/rf-profile-group",
    layouts: "TileLayout",
    element: <AddRFProfileGroup />,
    page_title: "Add Profile Group",
    is_drawer_item: "false",
    isauth: true,
    is_mobile: "true",
    iconsvg: faCircle,
  },
  {
    text: "Add Profile",
    icon: "k-i-minus",
    id: 29,
    parentid: 24,
    route: "/rf-profile",
    layouts: "TileLayout",
    element: <AddRFProfile />,
    page_title: "Add Profile",
    is_drawer_item: "false",
    isauth: true,
    is_mobile: "true",
    iconsvg: faCircle,
  },
  {
    text: "Form Info",
    icon: "k-i-minus",
    id: 32,
    parentid: 24,
    route: "/rf-form-infos",
    layouts: "CardLayout",
    element: <RFFormInfo />,
    page_title: "Form Info",
    is_drawer_item: "true",
    isauth: true,
    is_mobile: "true",
    iconsvg: faFileCircleCheck,
  },
  {
    text: "Add Form Info",
    icon: "k-i-minus",
    id: 33,
    parentid: 24,
    route: "/rf-form-info",
    layouts: "TileLayout",
    element: <AddRFFormInfo />,
    page_title: "Add Form Info",
    is_drawer_item: "false",
    isauth: true,
    is_mobile: "true",
    iconsvg: faCircle,
  },
  {
    text: "Field Info",
    icon: "k-i-minus",
    id: 34,
    parentid: 24,
    route: "/rf-field-infos",
    layouts: "CardLayout",
    element: <RFFieldInfo />,
    page_title: "Field Info",
    is_drawer_item: "true",
    isauth: true,
    is_mobile: "true",
    iconsvg: faFileCircleCheck,
  },
  {
    text: "Add Field Info",
    icon: "k-i-minus",
    id: 35,
    parentid: 24,
    route: "/rf-field-info",
    layouts: "TileLayout",
    element: <AddRFFieldInfo />,
    page_title: "Add Field Info",
    is_drawer_item: "false",
    isauth: true,
    is_mobile: "true",
    iconsvg: faCircle,
  },
  {
    text: "Organization",
    icon: "k-i-tell-a-friend",
    ["data-expanded"]: false,
    id: 30,
    route: "/organization",
    page_title: "Organization",
    layouts: "TileLayout",
    element: <Organization />,
    is_drawer_item: "true",
    isauth: true,
    is_mobile: "true",
    iconsvg: faBoxOpen,
  },
  {
    text: "Products",
    icon: "k-i-minus",
    id: 36,
    parentid: 30,
    route: "/organization-products",
    layouts: "CardLayout",
    element: <OrganizationProducts />,
    page_title: "Products",
    is_drawer_item: "true",
    isauth: true,
    is_mobile: "true",
    iconsvg: faFileCircleCheck,
  },
  {
    text: "Users",
    icon: "k-i-minus",
    id: 37,
    parentid: 30,
    route: "/organization-users",
    layouts: "TileLayout",
    element: <OrganizationUser />,
    page_title: "Users",
    is_drawer_item: "true",
    isauth: true,
    is_mobile: "true",
    iconsvg: faFileCircleCheck,
  },
  {
    text: "Redirect",
    route: "/AzureAdAuth/Callback",
    element: <AzureRedirectComponent />,
    layouts: "EmptyLayout",
    is_drawer_item: "false",
    is_mobile: "false",
  },
  {
    text: "Redirect",
    route: "/SocialMediaAuth/CallBack",
    element: <SocialMediaRedirectComponent />,
    layouts: "EmptyLayout",
    is_drawer_item: "false",
    is_mobile: "false",
  },
  {
    separator: true,
    is_drawer_item: "true",
  },
];

const RoutesItems = RoutesItemsList;

export default RoutesItems;
